import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function Editprogram({ program, refreshprogram }) {
  const [selectedprogram, setSelectedprogram] = useState('');
  const [program_name, setName] = useState('');

  const handleEditprogram = () => {
    if (!selectedprogram || !program_name) {
      alert('Please select a program and fill out the name correctly.');
      return;
    }

    fetch('https://gxk1906.uta.cloud/php/edit-program.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `program_id=${selectedprogram}&program_name=${program_name}`})
      .then((response) => response.json())
      .then((data) => {
        alert(data.message);
        if (data.success) {
          setSelectedprogram('');
          setName('');
          refreshprogram();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <div className="container">
        <div className="content">
          <div className="dashboard">
      <h2>Edit Program</h2>
      <div className="container">
      {program && program.length > 0 ? (
        <div>
          <label htmlFor="program">Select program:</label>
          <select id="program" value={selectedprogram} onChange={(e) => setSelectedprogram(e.target.value)}>
            <option value="">Select a Program</option>
            {program.map((program) => (
              <option key={program.program_id} value={program.program_id}>
                {program.program_name}
              </option>
            ))}
          </select>
          <br />
          <label htmlFor="editName">New Program Name:</label>
          <input type="text" id="editName" value={program_name} onChange={(e) => setName(e.target.value)} />
          <br />
          <button onClick={handleEditprogram}>Edit Program</button>
        </div>
      ) : (
        <p>No Program available for editing.</p>
      )}
    </div> </div></div></div></div>
  );
}

export default Editprogram;