import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResetPassword = () => {
    if (email) {
      // Perform password reset logic here, e.g., send an email with reset instructions.
      // You can replace the following alert with your actual logic.
      alert(`Password reset instructions sent to ${email}`);
      setShowAlert(true);
    } else {
      alert('Please enter a valid email address.');
    }
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/Blog">Blog</Link>
        <Link to="/contactus">Contact Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/login">Login</Link>
      </nav>
      
      <div className="container">
        <center>
          <div className="content">
            <div className="text">
              <h2>Forgot Password</h2>
              <p>Enter your email address below, and we'll send you instructions on how to reset your password:</p>
              <div className="login-box">
                <div className="box-heading">Email:</div>
                <input
                  type="email"
                  className="box-input"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <div className="box-button">
                  <button onClick={handleResetPassword}>RESET PASSWORD</button>
                </div>
              </div>
            </div>
            {showAlert && (
              <div className="alert">
                Password reset instructions sent to {email}.
              </div>
            )}
          </div>
        </center>
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default ForgotPassword;
