import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Gradeexam() {
  const [answers, setAnswers] = useState([]);
  const [marks, setMarks] = useState([0, 0, 0, 0, 0]); // Initial marks state with 0 values for each answer
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchAnswers();
  }, []);

  const fetchAnswers = () => {
    fetch('https://gxk1906.uta.cloud/php/retrieve-answers.php') // Replace with your API endpoint for fetching answers
      .then((response) => response.json())
      .then((data) => {
        setAnswers(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setError(error);
        setLoading(false);
      });
  };

  const handleMarkChange = (index, value) => {
    const newMarks = [...marks];
    newMarks[index] = parseInt(value, 10); // Parse value as an integer
    setMarks(newMarks);
  };

  const handleSubmitMarks = () => {
    // Assuming each answer has a unique ID, you need to adjust this logic based on your API structure
    const marksData = answers.map((answer, index) => ({
      answerId: answer.answer_id,
      marks: marks[index],
    }));

    // Now you can send marksData to your backend API for updating marks in the database
    console.log('Marks Data:', marksData);

    // Reset marks to zeros after submission
    setMarks([0, 0, 0, 0, 0]);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: 'auto' }}>
      <header>
        <h1 style={{ textAlign: 'center' }}>EDUHUB-360</h1>
      </header>
      <nav style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/Blog">Blog</Link>
        <Link to="/ContactUs">Contact Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div style={{ textAlign: 'center' }}>
        <h2>Grade Exams</h2>
      </div>
      <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="content" style={{ width: '100%' }}>
          <div className="dashboard" style={{ textAlign: 'center' }}>
            <h3>Exam Answers:</h3>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
              <thead style={{ backgroundColor: '#f2f2f2' }}>
                <tr>
                  <th style={{ padding: '10px', border: '1px solid #ddd' }}>Question</th>
                  <th style={{ padding: '10px', border: '1px solid #ddd' }}>Answer</th>
                  <th style={{ padding: '10px', border: '1px solid #ddd' }}>Marks</th>
                </tr>
              </thead>
              <tbody>
                {answers.map((answer, index) => (
                  <tr key={index}>
                    <td style={{ padding: '10px', border: '1px solid #ddd' }}>{answer.question}</td>
                    <td style={{ padding: '10px', border: '1px solid #ddd' }}>{answer.answer}</td>
                    <td style={{ padding: '10px', border: '1px solid #ddd' }}>
                      <input
                        type="number"
                        value={marks[index]}
                        onChange={(e) => handleMarkChange(index, e.target.value)}
                        style={{ padding: '5px' }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={handleSubmitMarks} style={{ marginTop: '20px', padding: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
              Submit Marks
            </button>
          </div>
        </div>
      </div>
      <footer style={{ marginTop: '20px', textAlign: 'center' }}>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default Gradeexam;
