import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function EditCoordinator({ coordinators, refreshCoordinators }) {
  const [selectedCoordinator, setSelectedCoordinator] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [co_email, setCo_email] = useState('');


  const handleEditCoordinator = () => {
    if (!selectedCoordinator || !name || !description || !co_email) {
      alert('Please select a student and fill out all fields.');
      return;
    }

    fetch('https://gxk1906.uta.cloud/php/edit-coordinator.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `id=${selectedCoordinator}&name=${name}&description=${description}&co_email=${co_email}`,
    })
      .then((response) => response.json())
      .then((data) => {
        alert(data.message);
        if (data.success) {
          setSelectedCoordinator('');
          setName('');
          setDescription('');
          setCo_email('');  
          refreshCoordinators();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <div className="container">
        <div className="content">
          <div className="dashboard">
      <h2>Edit Co-ordinator</h2>
      <div className="container">
      {coordinators && coordinators.length > 0 ? (
        <div>
          <label htmlFor="Coordinator">Select Co-ordinator:</label>
          <select id="Coordinator" value={selectedCoordinator} onChange={(e) => setSelectedCoordinator(e.target.value)}>
            <option value="">Select a Co-ordinator</option>
            {coordinators.map((coordinator) => (
              <option key={coordinator.id} value={coordinator.id}>
                {coordinator.name}
              </option>
            ))}
          </select>
          <br />
          <label htmlFor="editName">New Name:</label>
          <input type="text" id="editName" value={name} onChange={(e) => setName(e.target.value)} />
          <br />
            <label htmlFor="editDescription">New Description:</label>
            <input type="text" id="editDescription" value={description} onChange={(e) => setDescription(e.target.value)} />
            <br />
            <label htmlFor="editCo_email">New Email:</label>
            <input type="email" id="editCo_email" value={co_email} onChange={(e) => setCo_email(e.target.value)} />
            <br />
          
          <button onClick={handleEditCoordinator}>Edit Co-ordinator</button>
        </div>
      ) : (
        <p>No Co-ordinators available for editing.</p>
      )}
    </div> </div></div></div></div>
  );
}

export default EditCoordinator;