// import React, { useState, useEffect } from 'react';
// import AddStudent from './AddStudent';
// import EditStudent from './EditStudent';
// import { Link } from 'react-router-dom';

// // CSS for the grid layout
// const gridStyles = {
//   display: 'grid',
//   gridTemplateColumns: '1fr 1fr', // Two equal-width columns
//   gridGap: '20px', // Adjust the gap between columns as needed
// };

// function ManageStudents() {
//   const [students, setStudents] = useState([]);

//   useEffect(() => {
//     // Fetch the list of Students to populate the list
//     fetch('https://gxk1906.uta.cloud/php/retrieve-students.php')
//       .then((response) => response.json())
//       .then((data) => {
//         setStudents(data);
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//       });
//   }, []);

//   return (
//     <div>
//       <header>
//         <h1>EDUHUB-360</h1>
//       </header>
//       <nav>
//         <Link to="/">Home</Link>
//         <Link to="/about">About</Link>
//         <Link to="/Blog">Blog</Link>
//         <Link to="/ContactUs">Contact Us</Link>
//         <Link to="/services">Services</Link>
//         <Link to="/">Logout</Link>
//       </nav>
//       <div className="container">
//         <center>
//           <h1>Manage Students</h1>
//         </center>

//         <div style={gridStyles}>
//           <AddStudent refreshStudents={() => {
//             // Implement a function to refresh the Students list after adding or editing
//             fetch('http://localhost/giri/retrieve-students.php')
//               .then((response) => response.json())
//               .then((data) => {
//                 setStudents(data);
//               })
//               .catch((error) => {
//                 console.error('Error:', error);
//               });
//           }} />

//           <EditStudent students={students} refreshStudents={() => {
//             // Implement a function to refresh the Students list after adding or editing
//             fetch('http://localhost/giri/retrieve-students.php')
//               .then((response) => response.json())
//               .then((data) => {
//                 setStudents(data);
//               })
//               .catch((error) => {
//                 console.error('Error:', error);
//               });
//           }} />
//         </div>

//         {/* Render the list of Students */}
//         <ul>
//           {students ? (
//             students.map((student) => (
//               <li key={student.id}>
//                 {student.name} - {student.email} - {student.student}
//               </li>
//             ))
//           ) : (
//             <li>No students available</li>
//           )}
//         </ul>
//       </div>
//       <footer>
//         <p>&copy; Copyright EDUHUB-360</p>
//       </footer>
//     </div>
//   );
// }

// export default ManageStudents;



import React, { useState, useEffect } from 'react';
import Addprogram from './Addprogram';
import Editprogram from './Editprogram';
import { Link } from 'react-router-dom';

const gridStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridGap: '20px',
};

function Manageprogram() {
  const [program, setprogram] = useState([]);

  useEffect(() => {
    // Fetch the list of program to populate the list
    fetch('https://gxk1906.uta.cloud/php/retrieve-program.php')
      .then((response) => response.json())
      .then((data) => {
        setprogram(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  const handleDelete = (program_id) => {
    // Send a DELETE request to delete the student with the specified id
    fetch(`https://gxk1906.uta.cloud/php/delete-program.php?program_id=${program_id}`, {
      method: 'DELETE',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // If the deletion is successful, fetch the updated list of students
          fetch('https://gxk1906.uta.cloud/php/retrieve-program.php')
            .then((response) => response.json())
            .then((updatedData) => {
              setprogram(updatedData);
            })
            .catch((error) => {
              console.error('Error fetching updated program:', error);
            });
        } else {
          console.error('Error deleting student:', data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/Blog">Blog</Link>
        <Link to="/ContactUs">Contact Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <center>
          <h1>Manage Program</h1>
        </center>

        <div style={gridStyles}>
          <Addprogram
            refreshprogram={() => {
              fetch('https://gxk1906.uta.cloud/php/add-program.php')
                .then((response) => response.json())
                .then((data) => {
                  setprogram(data);
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
            }}
          />

          <Editprogram program={program} refreshprogram={() => {
            fetch('https://gxk1906.uta.cloud/php/retrieve-program.php')
              .then((response) => response.json())
              .then((data) => {
                setprogram(data);
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          }} />
        </div>

        {/* Render the list of program with a delete button */}
        <ul>
          {program ? (
            program.map((program) => (
              <li key={program.program_id}>
                {program.program_name}
                <button onClick={() => handleDelete(program.program_id)}>Delete</button>
              </li>
            ))
          ) : (
            <li>No program available</li>
          )}
        </ul>
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default Manageprogram;
