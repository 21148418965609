import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const AddQuestions = () => {
  const { exam_id } = useParams('');
  const [question, setQuestion] = useState('');
  const [optionA, setOptionA] = useState('');
  const [optionB, setOptionB] = useState('');
  const [optionC, setOptionC] = useState('');
  const [optionD, setOptionD] = useState('');
  const [correctOption, setCorrectOption] = useState('');
  const [questionsList, setQuestionsList] = useState([]);

  useEffect(() => {
    getAllQuestions();
  }, []); 

  async function getAllQuestions()  {
    fetch(`https://gxk1906.uta.cloud/php/retrieve-questions.php?examId=${exam_id}`)
    .then((response) => response.json())
    .then((data) => {
        setQuestionsList(data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  const handleAddQuestion = (e) => {
    e.preventDefault()
    if (!exam_id || !question || !optionA || !optionB || !optionC || !optionD || !correctOption) {
        alert('Please fill out all fields.');
        return;
      }
    console.log({exam_id: exam_id, question: question, optionA: optionA, optionB: optionB, optionC: optionC, optionD: optionD, correctOption: correctOption})
      fetch('https://gxk1906.uta.cloud/php/add-question.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `exam_id=${exam_id}&question=${question}&optionA=${optionA}&optionB=${optionB}&optionC=${optionC}&optionD=${optionD}&correctOption=${correctOption}`,
      })
        .then((response) => response.json())
        .then((data) => {
          alert(data.message);
          if (data.success) {
            setQuestion('');
            setOptionA('');
            setOptionB('');
            setOptionC('');
            setOptionD('');
            setCorrectOption('');
            getAllQuestions();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Add Questions for Exam ID: {exam_id}</h2>
      <form style={styles.form} onSubmit={handleAddQuestion}>
        <div style={styles.formGroup}>
          <label style={styles.label}>Question:</label>
          <input
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Option A:</label>
          <input
            type="text"
            value={optionA}
            onChange={(e) => setOptionA(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Option B:</label>
          <input
            type="text"
            value={optionB}
            onChange={(e) => setOptionB(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Option C:</label>
          <input
            type="text"
            value={optionC}
            onChange={(e) => setOptionC(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Option D:</label>
          <input
            type="text"
            value={optionD}
            onChange={(e) => setOptionD(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label style={styles.label}>Correct Option:</label>
          <input
            type="text"
            value={correctOption}
            onChange={(e) => setCorrectOption(e.target.value)}
            style={styles.input}
          />
        </div>
        <button type="submit" style={styles.addButton}>
          Add Question
        </button>
      </form>

      {questionsList.length > 0 && (
        <div style={styles.questionsList}>
          <h3 style={styles.subHeading}>Questions Added:</h3>
          <ol style={styles.ol}>
            {questionsList.map((q, index) => (
              <li key={index} style={styles.li}>
                <strong>Question {index + 1}:</strong> {q.question} <br />
                <strong>Option A:</strong> {q.optionA} <br />
                <strong>Option B:</strong> {q.optionB} <br />
                <strong>Option C:</strong> {q.optionC} <br />
                <strong>Option D:</strong> {q.optionD} <br />
                <strong>Correct Option:</strong> {q.correct_option}
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    margin: '20px',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#f8f9fa',
    padding: '20px',
    borderRadius: '8px',
  },
  heading: {
    color: '#007bff',
    textAlign: 'center',
  },
  form: {
    marginTop: '20px',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#555',
  },
  input: {
    padding: '10px',
    fontSize: '16px',
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  addButton: {
    padding: '10px',
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
  },
  questionsList: {
    marginTop: '20px',
  },
  subHeading: {
    color: '#333',
    textAlign: 'center',
  },
  ol: {
    listStyle: 'decimal',
    padding: '0',
    margin: '0',
  },
  li: {
    margin: '10px 0',
    backgroundColor: '#ffffff',
    padding: '10px',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  saveButton: {
    padding: '10px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
    width: '100%',
  },
};

export default AddQuestions;

