import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import FeedbackDisplay from './FeedbackDisplay';

function AdminDashboard() {
  const [chatInput, setChatInput] = useState('');
  const [chatContent, setChatContent] = useState([]);

  const chatbotResponses = {
    'hi': 'Hello!',
    'how are you?': 'I am just a chatbot. How can I help you today?',
    'reviews': 'You can access reviews from the Reviews section.',
    'assigned students': 'View assigned students in the Assigned Students section.',
    'handle course': 'Handle course-related tasks in the Handle Course section.',
    'manage course': 'Manage courses in the Manage Course section.',
    'reports': 'Access reports from the Reports section.',
    'performance': 'View performance details in the Performance section.',
    'manage students': 'Manage student records in the Manage Students section.',
    'assigned course': 'View assigned courses in the Assigned Course section.',
    'qa reports': 'Access QA reports from the Reports section.',
    'manage qa officer': 'Manage QA officers in the Manage QA Officer section.',
    'permissions': 'Set permissions in the Permissions section.',
    'clear chat': 'Chat cleared.',
  };

  const handleChatInputChange = (e) => {
    setChatInput(e.target.value);
  };

  const handleChatSubmit = () => {
    if (chatInput.trim() !== '') {
      const message = chatInput.toLowerCase();
      const newMessage = `You: ${chatInput}`;
      setChatContent([...chatContent, newMessage]);
      
      if (chatbotResponses[message]) {
        const response = chatbotResponses[message];
        setChatContent([...chatContent, `Chatbot: ${response}`]);
      } else {
        setChatContent([...chatContent, 'Chatbot: I don\'t understand your question.']);
      }
      
      setChatInput('');
    }
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/managecourses">Courses</Link>
        <Link to="/Manageprogram">Program</Link>
        <Link to="/FeedbackDisplay">Feedbacks</Link> {/* Link to the FeedbackDisplay page */}
        <Link to="/reportspage">Reports</Link>
        <Link to="/">Logout</Link>
      </nav>
      <Switch>
        <Route path="/FeedbackDisplay" component={FeedbackDisplay} />
      </Switch>
      <div className="container">
        <div className="content">
          <div className="dashboard">
            <div className="section">
              <h2>Coordinator</h2>
              {/* <Link to="/programreviewpage"><button>Reviews</button></Link> */}
              {/* <Link to="/assigned-students"><button>Assigned Students</button></Link> */}
              <Link to="/managecoordinator"><button>Manage Coordinator</button></Link>
            </div>
            <div className="section">
              <h2>Student</h2>
              {/* <Link to="/managecourses"><button>Manage Course</button></Link> */}
              {/* <Link to="/reportspage"><button>Reports</button></Link> */}
              {/* <Link to="/performance"><button>Performance</button></Link> */}
              <Link to="/managestudents"><button>Manage Students</button></Link>
            </div>
            <div className="section">
              <h2>QA Officer</h2>
              {/* <Link to="/managecourses"><button>Assign Course</button></Link> */}
              {/* <Link to="/reportqa"><button>Reports</button></Link> */}
              <Link to="/manageqa"><button>Manage QA Officer</button></Link>
              {/* <Link to="/permissions"><button>Permissions</button></Link> */}
            </div>
          </div>
        </div>
        <div className="chat-box">
          <div className="close-button">X</div>
          <div className="chat-title">Chat here !</div>
          <br></br>
            <div className="email-input-wrapper">
                <label htmlFor="userEmail">Your Email:</label>
                <br></br>
                <input
                  type="email"
                  id="userEmail"
                  placeholder="Enter email"
                />
              </div>
          <div className="chat-content">
            {chatContent.map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </div>
          <input
            type="text"
            className="chat-input"
            placeholder="Type here"
            value={chatInput}
            onChange={handleChatInputChange}
          />
          <button className="chat-button" onClick={handleChatSubmit}>
            SEND
          </button>
        </div>
      </div>
      <footer>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default AdminDashboard;
