import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const QADashboard = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const chatbotResponses = {
    'hi': 'Hello!',
    'how are you?': 'I am just a chatbot. How can I help you today?',
    'what is your role?': 'I am here to assist you with program-related queries.',
    'tell me about program review': 'Program review is a comprehensive evaluation of our academic programs to ensure quality and effectiveness.',
    'how can I improve assessments?': 'You can improve assessments by implementing best practices in assessment design and feedback.',
    'faculty development': 'Faculty development programs aim to enhance teaching and research skills of our educators.',
    'what are the quality standards?': 'Quality standards ensure the program meets the highest educational standards.',
    'tell me about student support services': 'Student support services provide assistance to students in various aspects of their academic journey.',
    'how can I contact support?': 'You can reach our support team at support@example.com.',
    'can you recommend study materials?': 'We recommend the following study materials: (List of materials).',
    'how can I reset my password?': 'You can reset your password by visiting our login page and clicking on the "Forgot Password" link.',
    'tell me about the admission process': 'You can find information about the admission process on our website.',
    'what are the tuition fees?': 'Tuition fees vary, please check our website for the most up-to-date information.',
    'where is the campus located?': 'Our campus is located at (address).',
    'what is the program schedule?': 'You can find the program schedule on our website.',
    'tell me more about the faculty': 'Our faculty members are experienced professionals with expertise in various fields.',
    
  };

  const handleEmailInputChange = (event) => {
    setUserEmail(event.target.value);
  };

  const handleChatInputChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendChatMessage = () => {
    const message = newMessage.toLowerCase();
    if (message === 'clear chat') {
      setChatMessages([]);
    } else {
      setChatMessages([...chatMessages, { message: newMessage, user: true }]);
      if (chatbotResponses[message]) {
        const response = chatbotResponses[message];
        setChatMessages([...chatMessages, { message: response, user: false }]);
      } else {
        setChatMessages([...chatMessages, { message: "I don't understand your question.", user: false }]);
      }
      setNewMessage('');
    }
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/managestudents">Student</Link>
        {/* <Link to="/Messages-qa">Message</Link> */}
        <Link to="/manageinstructors">Instructor</Link>
        <Link to="/progcord-qa">Coordinator</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <div className="content">
          <div className="dashboard">
            <div className="section">
              <h2>QA Officer</h2>
              <div className="link-wrapper" style={{ marginBottom: '10px' }}>
                <button><Link to="/reports">Reports</Link></button>
              </div>
              <div className="link-wrapper" style={{ marginBottom: '10px' }}>
                <button><Link to="/feedbackpage">Give Feedback</Link></button>
              </div>
              <div className="link-wrapper" style={{ marginBottom: '10px' }}>
                <button><Link to="/qualitystandardspage">Quality Standards</Link></button>
              </div>
            </div>
          </div>
        </div>
        <div className="chat-box">
          <div className="close-button">X</div>
          <div className="chat-title">Chat here !</div>
          <div className="email-input-wrapper">
                <label htmlFor="userEmail">Your Email:</label>
                <input
                  type="email"
                  id="userEmail"
                  value={userEmail}
                  onChange={handleEmailInputChange}
                  placeholder="Enter email"
                />
              </div>
          <div className="chat-content">
            {chatMessages.map((message, index) => (
              <div key={index} className={message.user ? 'user-message' : 'chatbot-message'}>
                {message.message}
              </div>
            ))}
          </div>
          <div className="email-input-wrapper">
            <input
              type="email"
              className="chat-input"
              placeholder="Type here"
              value={newMessage}
              onChange={handleChatInputChange}
            />
            <button className="chat-button" onClick={handleSendChatMessage}>
              SEND
            </button>
          </div>
        </div>
      </div>
      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
};

export default QADashboard;
