// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// function ExamCreation() {
//   const [examName, setExamName] = useState('');
//   const [selectedCourse, setSelectedCourse] = useState('');
//   const [exams, setExams] = useState([]);
//   const [courses, setCourses] = useState(['CSE 5321-001', 'CSE 5334-004', 'CSE 6324-003']); // Sample set of courses

//   const handleCreateExam = () => {
//     if (examName && selectedCourse) {
//       setExams([...exams, { course: selectedCourse, name: examName }]);
//       setExamName('');
//       setSelectedCourse('');
//     }
//   };

//   return (
//     <div>
//       <header>
//         <h1>EDUHUB-360</h1>
//       </header>
//       <nav>
//       <Link to="/">Home</Link>
//       <Link to="/about">About</Link>
//       <Link to="/Blog">Blog</Link>
//       <Link to="/ContactUs">Contact Us</Link>
//       <Link to="/services">Services</Link>
//       <Link to="/">Logout</Link>
//     </nav>
//       <center><h2>Create Exams</h2>
//       </center>
//       <div className="container">
//         <div className="content">
//           <div className="dashboard">
     
//       <div>
//         <input
//           type="text"
//           placeholder="Enter exam name"
//           value={examName}
//           onChange={(e) => setExamName(e.target.value)}
//         />
//         <select
//           value={selectedCourse}
//           onChange={(e) => setSelectedCourse(e.target.value)}
//         >
//           <option value="">Select a course</option>
//           {courses && courses.length > 0 ? (
//             courses.map((course, index) => (
//               <option key={index} value={course}>
//                 {course}
//               </option>
//             ))
//           ) : (
//             <option value="">No courses available</option>
//           )}
//         </select>
//         <button onClick={handleCreateExam}>Create Exam</button>
//       </div>
//       <h3>Created Exams:</h3>
//       <table>
//         <thead>
//           <tr>
//             <th>Course</th>
//             <th>Exam Name</th>
//           </tr>
//         </thead>
//         <tbody>
//           {exams.map((exam, index) => (
//             <tr key={index}>
//               <td>{exam.course}</td>
//               <td>{exam.name}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div> </div></div><footer>
//         <p>&copy; Copyright EDUHUB-360</p>
//       </footer></div>
//   );
// }

// export default ExamCreation;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function ExamCreation() {
  const [examName, setExamName] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [totalMarks, setTotalMarks] = useState();
  const [passingMarks, setPassingMarks] = useState();

  // const [answerOptions, setAnswerOptions] = useState(['', '', '', '']);
  const [exams, setExams] = useState([]);
  const [courses, setCourses] = useState(['CSE 5321-001', 'CSE 5334-004', 'CSE 6324-003']); // Sample set of courses

  useEffect(() => {
    getAllExams();
  }, []); 

  async function  getAllExams  ()  {
    fetch('https://gxk1906.uta.cloud/php/retrieve-exams.php')
    .then((response) => response.json())
    .then((data) => {
        setExams(data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }

  const handleCreateExam = () => {
    if (!examName || !selectedCourse || !totalMarks || !passingMarks) {
      alert('Please fill out all fields.');
      return;
    }
    //console.log({exam: examName, course: selectedCourse, totalMarks: parseInt(totalMarks,10), passingMarks: parseInt(passingMarks,10)})
    fetch('https://gxk1906.uta.cloud/php/add-exam.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `examName=${examName}&selectedCourse=${selectedCourse}&totalMarks=${totalMarks}&passingMarks=${passingMarks}`,
    })
      .then((response) => response.json())
      .then((data) => {
        alert(data.message);
        if (data.success) {
          //setExams([...exams, { course: selectedCourse, name: examName, totalMarks: totalMarks, passingMarks: passingMarks}]);
          setExamName('');
          setSelectedCourse('');
          // setQuestion('');
          // setAnswerOptions(['', '', '', '']);
          setTotalMarks('');
          setPassingMarks('');
          getAllExams();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
      
  };


  // const handleCreateExam = () => {
  //   if (examName && selectedCourse && question && answerOptions.every(option => option !== '')) {
  //     setExams([...exams, { course: selectedCourse, name: examName, question, answers: [...answerOptions] }]);
  //     setExamName('');
  //     setSelectedCourse('');
  //     setQuestion('');
  //     setAnswerOptions(['', '', '', '']);
  //     console.log({exam: examName, course: selectedCourse, question: question, optionA: answerOptions[0], optionB: answerOptions[1], optionC: answerOptions[2], optionD: answerOptions[3]})
      
  //   }
  // };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: 'auto' }}>
      <header>
        <h1 style={{ textAlign: 'center' }}>EDUHUB-360</h1>
      </header>
      <nav style={{ textAlign: 'center', marginBottom: '20px' }}>
        <Link to="/InstructorDashboard">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/Blog">Blog</Link>
        <Link to="/ContactUs">Contact Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div style={{ textAlign: 'center' }}>
        <h2>Create Exams</h2>
      </div>
      <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="content" style={{ width: '100%' }}>
          <div className="dashboard" style={{ textAlign: 'center' }}>
            <div>
              <input
                type="text"
                placeholder="Enter exam name"
                value={examName}
                onChange={(e) => setExamName(e.target.value)}
                style={{ marginBottom: '10px', padding: '5px' }}
              />
              <select
                value={selectedCourse}
                onChange={(e) => setSelectedCourse(e.target.value)}
                style={{ marginBottom: '10px', padding: '5px' }}
              >
                <option value="">Select a course</option>
                {courses && courses.length > 0 ? (
                  courses.map((course, index) => (
                    <option key={index} value={course}>
                      {course}
                    </option>
                  ))
                ) : (
                  <option value="">No courses available</option>
                )}
              </select>
              <input
                type="number"
                placeholder="Enter total marks of the exam"
                value={totalMarks}
                onChange={(e) => setTotalMarks(e.target.value)}
                style={{ marginBottom: '10px', padding: '5px' }}
              />
              <input
                type="number"
                placeholder="Enter passing marks of the exam"
                value={passingMarks}
                onChange={(e) => setPassingMarks(e.target.value)}
                style={{ marginBottom: '10px', padding: '5px' }}
              />
              {/* {[1, 2, 3, 4].map((index) => (
                <input
                  key={index}
                  type="text"
                  placeholder={`Enter answer ${index}`}
                  value={answerOptions[index - 1]}
                  onChange={(e) => {
                    const newOptions = [...answerOptions];
                    newOptions[index - 1] = e.target.value;
                    setAnswerOptions(newOptions);
                  }}
                  style={{ marginBottom: '10px', padding: '5px' }}
                />
              ))} */}
              <button onClick={handleCreateExam} style={{ padding: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                Create Exam
              </button>
            </div>
            <h3 style={{ marginTop: '20px' }}>Created Exams:</h3>
            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
              <thead style={{ backgroundColor: '#f2f2f2' }}>
                <tr>
                  <th style={{ padding: '10px', border: '1px solid #ddd' }}>Course</th>
                  <th style={{ padding: '10px', border: '1px solid #ddd' }}>Exam Name</th>
                  <th style={{ padding: '10px', border: '1px solid #ddd' }}>Total Marks</th>
                  <th style={{padding: '10px', border: '1px solid #ddd'}}>Action</th>
                </tr>
              </thead>
              <tbody>
                {exams.map((exam, index) => (
                  <tr key={index}>
                    <td style={{ padding: '10px', border: '1px solid #ddd' }}>{exam.course}</td>
                    <td style={{ padding: '10px', border: '1px solid #ddd' }}>{exam.exam_name}</td>
                    <td style={{ padding: '10px', border: '1px solid #ddd' }}>{exam.total_marks}</td>
                    {/* <td><button onClick={'<Link to="/">Home</Link>} style={{ padding: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                      Add Question
                    </button></td> */}
                    <td><button style={{ padding: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px' , cursor: 'pointer'}}><Link to={`/Addquestions/${exam.exam_id}`}>Add Questions</Link></button></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <footer style={{ marginTop: '20px', textAlign: 'center' }}>
        <p>&copy; Copyright EDUHUB-360</p>
      </footer>
    </div>
  );
}

export default ExamCreation;
