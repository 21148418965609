import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ProgramCoordinatorDashboard = () => {
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  const chatbotResponses = {
    'hi': 'Hello!',
    'how are you?': 'I am just a chatbot. How can I help you today?',
    'what is the program schedule?': 'You can find the program schedule on our website.',
    'how can I contact support?': 'You can reach our support team at support@example.com.',
    'tell me about the admission process.': 'You can find information about the admission process on our website.',
    'what are the tuition fees?': 'Tuition fees vary, please check our website for the most up-to-date information.',
    'can you recommend some study materials?': 'We recommend the following study materials: (List of materials).',
    'how can I reset my password?': 'You can reset your password by visiting our login page and clicking on the "Forgot Password" link.',
    'what are the office hours?': 'Our office hours are from 9:00 AM to 5:00 PM, Monday to Friday.',
    'tell me more about the faculty.': 'Our faculty members are experienced professionals with expertise in various fields.',
    'where is the campus located?': 'Our campus is located at (address).'
    // Add more question-response pairs here
  };

  const handleChatInputChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleSendChatMessage = () => {
    const message = newMessage.toLowerCase(); 
    if (message === 'bye') {
      // Clear the chat
      setChatMessages([]);
    } else {
      setChatMessages([...chatMessages, { message: newMessage, user: true }]);
      // Check if the message has a predefined response
      if (chatbotResponses[message]) {
        const response = chatbotResponses[message];
        setChatMessages([...chatMessages, { message: response, user: false }]);
      } else {
        setChatMessages([...chatMessages, { message: "I don't understand your question.", user: false }]);
      }
      setNewMessage('');
    }
  };

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        {/* <Link to="/managestudents">Student</Link>
        <Link to="/messages-pro">Message</Link>
        <Link to="/manageinstructors">Instructor</Link> */}
        <Link to="/">Home</Link> 
        {/* <Link to="/test">Files</Link>    */}
        <Link to="/FeedbackPage">Feedback</Link>
        <Link to="/managestudents">Students</Link>
        <Link to="/">Logout</Link>        
      </nav>
      <div className="container">
        <div className="content">
          <div className="dashboard">
            <div className="section">
              <h2>Program Coordinator Dashboard</h2>
              <button><Link to="/reportspage">Reports</Link></button>&nbsp;
              <button><Link to="/manageinstructors">Manage Instructors</Link></button>&nbsp;
              <button><Link to="/managestudents">Manage Students</Link></button>&nbsp;
              <button><Link to="/FeedbackDisplay">View Feedback</Link></button>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="chat-box">
            <div className="close-button">X</div>
            <div className="chat-title">Chat here !</div>
            <br></br>
            <div className="email-input-wrapper">
                <label htmlFor="userEmail">Your Email:</label>
                <br></br>
                <input
                  type="email"
                  id="userEmail"
                  placeholder="Enter email"
                />
              </div>
            <div className="chat-content">
              {chatMessages.map((message, index) => (
                <div key={index} className={message.user ? 'user-message' : 'chatbot-message'}>
                  {message.message}
                </div>
              ))}
            </div>
            <input
              type="text"
              className="chat-input"
              placeholder="Type here"
              value={newMessage}
              onChange={handleChatInputChange}
            />
            <button className="chat-button" onClick={handleSendChatMessage}>
              SEND
            </button>
          </div>
        </div>
      </div>
      <footer>
        <p>@ copyright EDUHUB-360</p>
      </footer>
    </div>
  );
};

export default ProgramCoordinatorDashboard;
