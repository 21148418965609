import React, { useState } from 'react';
import { Link } from 'react-router-dom';
function Addprogram({ refreshStudents }) {
  const [program_name, setName] = useState('');

  const handleAddProgram = () => {
    if (!program_name) {
      alert('Please fill out the Program name.');
      return;
    }

    fetch('https://gxk1906.uta.cloud/php/add-program.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `program_name=${program_name}`,
    })
      .then((response) => response.json())
      .then((data) => {
        alert(data.message);
        if (data.success) {
          setName('');
          refreshStudents();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
    <div className="container">
        <div className="content">
          <div className="dashboard">
            <h2>Add Program</h2>
      <div className="container">
      <label htmlFor="name">Program Name:</label>
      <input type="text" id="name" value={program_name} onChange={(e) => setName(e.target.value)} />
      <br />
      <button onClick={handleAddProgram}>Add Program</button>
    </div></div></div></div> </div>
  );
}

export default Addprogram;
