import React, { useState, useEffect } from 'react';
import AddCourse from './AddCourse';
import EditCourse from './EditCourse';
import { Link } from 'react-router-dom';

// CSS for the grid layout
const gridStyles = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr', // Two equal-width columns
  gridGap: '20px', // Adjust the gap between columns as needed
};

function ManageCourses() {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    // Fetch the list of Courses to populate the list
    fetch('https://gxk1906.uta.cloud/php/retrieve-courses.php')
      .then((response) => response.json())
      .then((data) => {
        setCourses(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <div>
      <header>
        <h1>EDUHUB-360</h1>
      </header>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/Blog">Blog</Link>
        <Link to="/ContactUs">Contact Us</Link>
        <Link to="/services">Services</Link>
        <Link to="/">Logout</Link>
      </nav>
      <div className="container">
        <center>
          <h1>Manage Courses</h1>
        </center>

        <div style={gridStyles}>
          <AddCourse refreshCourses={() => {
            // Implement a function to refresh the Courses list after adding or editing
            fetch('https://gxk1906.uta.cloud/php/retrieve-courses.php')
              .then((response) => response.json())
              .then((data) => {
                setCourses(data);
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          }} />

          <EditCourse courses={courses} refreshCourses={() => {
            // Implement a function to refresh the Courses list after adding or editing
            fetch('https://gxk1906.uta.cloud/php/retrieve-courses.php')
              .then((response) => response.json())
              .then((data) => {
                setCourses(data);
              })
              .catch((error) => {
                console.error('Error:', error);
              });
          }} />
        </div>

        {/* Render the list of Courses */}
        <ul>
          {courses ? (
            courses.map((course) => (
              <li key={course.id}>
                {course.name} - {course.email} - {course.course}
              </li>
            ))
          ) : (
            <li>No courses available</li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default ManageCourses;
