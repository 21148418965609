import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const AddQuestions = () => {
  const { exam_id } = useParams();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [questionsList, setQuestionsList] = useState([
    {
      question: 'Sample Question 1',
      options: ['Option A', 'Option B', 'Option C', 'Option D'],
      correctOption: 'Option A',
    },
    {
      question: 'Sample Question 2',
      options: ['Option X', 'Option Y', 'Option Z', 'Option W'],
      correctOption: 'Option Z',
    },
    // Add more questions as needed
  ]);

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleNextQuestion = () => {
    setQuestionIndex((prevIndex) => prevIndex + 1);
    setSelectedOption(null); // Reset selected option for the next question
  };

  const handleSubmitExam = () => {
    // You can handle the submission logic here
    console.log('Exam Submitted:', { exam_id, responses: questionsList });
  };

  return (
    <div style={styles.container}>
      <div style={styles.questionPanel}>
        <h2 style={styles.heading}>Exam ID:{exam_id}</h2>
        {questionIndex < questionsList.length ? (
          <>
            <h3 style={styles.questionHeading}>Question {questionIndex + 1}:</h3>
            <p style={styles.questionText}>{questionsList[questionIndex].question}</p>
            <div style={styles.optionsList}>
              {questionsList[questionIndex].options.map((option, index) => (
                <label key={index} style={styles.optionLabel}>
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOption === option}
                    onChange={() => handleOptionChange(option)}
                    style={styles.radioInput}
                  />
                  {option}
                </label>
              ))}
            </div>
            <button onClick={handleNextQuestion} style={styles.nextButton}>
              Next Question
            </button>
          </>
        ) : (
          <div style={styles.submitPanel}>
            <h3 style={styles.subHeading}>All questions answered!</h3>
            <button onClick={handleSubmitExam} style={styles.submitButton}>
              Submit Exam
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    fontFamily: 'Arial, sans-serif',
    maxWidth: '800px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  questionPanel: {
    flex: '1',
    backgroundColor: '#f8f9fa',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  heading: {
    color: '#007bff',
    textAlign: 'center',
  },
  questionHeading: {
    color: '#333',
  },
  questionText: {
    marginBottom: '15px',
  },
  optionsList: {
    marginBottom: '20px',
  },
  optionLabel: {
    display: 'block',
    marginBottom: '8px',
  },
  radioInput: {
    marginRight: '8px',
  },
  nextButton: {
    padding: '10px',
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    width: '100%',
  },
  submitPanel: {
    textAlign: 'center',
  },
  subHeading: {
    color: '#333',
  },
  submitButton: {
    padding: '10px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
  },
};

export default AddQuestions;
